export const mapConfig = {
        zoom: 7,        
        center: {lat: 42.645234602964514, lng: 25.017259689118312},
        size: { width: 'calc(100%)', height: '600px' },
}

export const serviceConfig = {
        serviceUrl: "https://campapi.osmap.org"
}

export const googleMapConfig = {
        googleClientId: "672108875979-0ips6gr56qkm87n8f808ql9hg7r5ve65.apps.googleusercontent.com",
        googleMapsApiKey: "AIzaSyDilfTGyWaUh06rJzTJARWDrbJku0n5Dao"
}

export const addPointCoing = {
        steps: 2 ,
        maxImagesPerPoint: 5
}